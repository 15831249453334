@import "styles/constants";
@import "sass-mq";

html {
  @include mq($until: tablet) {
    font-size: 56.25%;
  }

  font: 62.5% arial, sans-serif;
  overflow: auto scroll;
}

body {
  background: #fff;
  color: #000;
  font-size: 1.4rem;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

svg {
  vertical-align: top;
}
