.iconWrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.icon {
  color: #fff;
  height: 20px;
  width: 20px;
}
