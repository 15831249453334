.wrapper {
  border: 1px solid #ececec;
  border-radius: 8px;
}

.headerWrapper {
  align-items: center;
  background: #ececec;
  color: #6d6d84;
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.npmIcon {
  height: 24px;
  width: 24px;
}

.copyIcon,
.githubIcon {
  height: 16px;
  width: 16px;
}

.contentWrapper {
  display: grid;
  gap: 16px;
  padding: 16px;
}

.headingWrapper {
  align-items: center;
  border-bottom: 1px solid #ececec;
  display: flex;
  justify-content: space-between;
  padding: 0 0 8px;
}

.articleContentWrapper {
  padding: 8px 0 0;
}

.versionsWrapper {
  align-items: baseline;
  display: grid;
  gap: 4px;
  grid-auto-flow: column;
  justify-content: flex-start;
}

.types {
  font-size: 1.2rem;
}

.copyToClipboardWrapper {
  display: grid;
  gap: 4px;
  grid-auto-flow: column;
}
